:root {
    --main-color-orange: #fff7e6;
    --main-color-orange--lighter: rgba(240, 182, 56, 0.4);
    --main-color-orange--light-text: #4f4537db;
    --main-color-orange--light-bg: #4f4537db;
    --main-color-brown: #4f4537;
    --main-color-brown--light: rgb(79, 69, 55, 0.9);
    --main-color-green: #006e61;
    --main-color-grey--dark: #7e7e7e;
    --main-color-grey: #dfe0df;
}

body {
    margin: 0;
    padding: 0;
    
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.material-symbols-outlined {
    color: var(--main-color-green);
}
