.body{ 
  width: 100vw;
}

.App {
  background: url("./images/sidlo4.webp") no-repeat;
  background-size: 100%;
  width: 100%;
}


@media only screen and (max-width: 780px) {
  .App{
    background: url("./images/sidlo4-mobil.webp") no-repeat;
    background-size: 100%;
    width: 100%;
    margin-top: -0.5rem;
  }

  .App--mobile{
    background-color: var(--main-color-orange);
    height: 100vh;
    background-image: none;
    margin-top: 0;
  }

  .all-height{
    height: calc(100vh - 3.5rem);
    display: flex;
    align-items: center;
  }
}