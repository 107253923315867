.ad-box {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
}

.ad-image {
    width: 100%;
    
}

.ad-anchor {
    width: 40%;
    display: flex;
    padding: 0.5rem;
    border: 0.1rem var(--main-color-grey) solid;
    border-radius: 0.5rem;
}

.ad-ad {
    color: rgba(0, 0, 0, 0.445);
    font-size: 0.8rem;
    margin-left: -3rem;
    margin-top: -0.5rem;
}

@media only screen and (max-width: 780px) {
    .ad-anchor {
        width: 80%;
        
    }

    .ad-ad {
        font-size: 0.7rem;
    }
}
