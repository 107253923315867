.menu-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 3rem;
    background-color: var(--main-color);
  }
  
  .homePage-menuContainer {
    background-color: var(--main-color-orange);
    display: grid;
    grid-template-columns: [first] 22.5% [second] 55% [third] 22.5%;
    position: relative;
    box-shadow: 0px 5px 5px grey;
  }
  
  .homePage-item--menu {
    display: block;
  }
  
  .homePage-menu {
    margin: 0;
    padding: 1rem 3rem;
  
    list-style: none;
  
    display: flex;
    grid-column: second;
    justify-content: space-evenly;
  
  }
  
  .homePage-item {
    text-align: center;
  }
  
  .homePage-item-button--dark {
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: azure;
    background-color: var(--main-color-green);
  }
  
  .homePage-item-button--dark:hover {
    cursor: pointer;
  }
  
  .homePage-item--logo {
    grid-column: first;
    margin: 0.5rem;
  }
  
  .homePage-logo {
    width: 90%;
    margin: auto 0;  
    margin-top: 2rem;
  }
  
  .homePage-item-button {
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: none;
    font-size: 1.2rem;
  }
  
  .homePage-item-button:hover {
    background-color: var(--main-color-green);
    color: azure;
    transition: background-color 0.3s linear;
    cursor: pointer;
  }

  
  .homePage-item--dot {
    height: 11rem;
    width: 11rem;
    margin-top: -2rem;
    margin-left: -0.1rem;
    padding: 0.5rem;
    background-color: var(--main-color-orange);
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .homePage-item--menuButton, .homePage-menu--mobile, .unable-display{
    display: none;
  }

  @media only screen and (max-width: 800px) {

    .homePage-item--desctop{
      display: none;
    }

    .menu-container{
      position: unset;
      height: 3.5rem;
      width: 100%;

    }

    .menu-container--mobile{
      background-color: var(--main-color-orange);
      position: absolute;
      box-shadow: none;
      height: 100vh;
    }
    
    .homePage-menuContainer{
    
      box-shadow: none;
      background: transparent;
      height: 5rem;
    }

    .homePage-item--dot {
      height: 3.5rem;
      width: 3.5rem;
      margin-top: 1.5rem;
      margin-left: 1rem;
      background-color: var(--main-color-orange);
      border-radius: 50%;
      position: absolute;
      display: flex;
      justify-content: center;
    }

    .homePage-logo {
      width: 4rem;
      margin: auto 0;  
      margin-top: 0rem;
      margin-bottom: 0.3rem;
    }

    .homePage-menu{
      margin: 0;
      padding: 0;
    }

    .homePage-item--menuButton{
      border-radius: 50%;
      height: 3.5rem;
      width: 3.5rem;
      margin-top: 1.5rem;
      margin-right: 1rem;
      padding: 0.5rem;
      background-color: var(--main-color-orange);
      display: block;
      
    }

    .homePage-menuButton{
      border-style: none;      
      align-self: center;
      display: flex;
      justify-content: center;
      background-color: var(--main-color-orange);
      margin-top: 1rem;
      margin-right: 1rem;
      width: 3.5rem;
      
    }

    .homePage-menu--mobile{
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
    }

    .unable-display{
      display: none;
    }

    .homePage-menuContainer--nonShadow{
      box-shadow: none;
    }

    .homePage-item{
      padding: 1.5rem 0;
    }

    .homePage-item--button:active{
      background-color: var(--main-color-green);
      color: azure;
      transition: background-color 0.3s linear;
      cursor: pointer;
    }

  }
  