.footer {
    background-color: var(--main-color-brown);
    color: azure;
    margin-top: 3rem;
  }
  
.footer-bottom{
  width: 100%;

}

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    width: 70%;
    margin: 0 auto;
  }
  
  .footer-list {
    font-size: 1rem;
    text-transform: none;
    list-style: none;
    text-shadow: none;
    padding: 0;
    margin-top: 0.5rem
  }

  .footer-box02{
    align-self: flex-start;
  }

  .links-list{
    color: var(--main-color-orange)
  }
  
  .footer-item {
    padding: 0.4rem 0;
    /* display: flex; */
    justify-content: center;
    height: 1.2rem;
    color: var(--main-color-orange)
  }

  a{
    text-decoration: none;
    color: var(--main-color-orange);
  }

  .footer-item--underline{
    cursor: pointer;
  }
  
  .footer-item--underline:hover {
    color: azure;
  }
  
  a:hover {
    color: azure;
  }
  

  .footer-image{
    height: 70%;
    margin: auto 0;  
    margin-right: 0.5rem;
  }
  
  .footer-title{
    color: var(--main-color-orange);
  }

  @media only screen and (max-width: 780px) {
    .footer {
      background-position-x: center;
      background-position-y: 97%;
    }

    .footer-container {
      flex-direction: column;
      justify-content: center;
      font-size: 1rem;
      margin: 0;
      align-content: center;
      flex-wrap: wrap;
      padding: 1rem 0;
      width: 100%;
    }

    .footer-list {
      font-size: 0.8rem;
    }

    .footer-box01{
      margin-bottom: 1rem;
    }
  }