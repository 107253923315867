.services{
    padding-top: 7rem; 
}

.services-container{
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    justify-content: space-between;
    width: 70%;
    /* background-color: var(--main-color-orange--light-bg); */
    border-radius: 5rem;
    font-size: 1rem;
    text-align: center;
}

.services-icon{
    max-width: 4rem;
}

.services-box{
    max-width:20rem;
    margin: 0.7rem;
    padding: 1.3rem;
    border: 0.12rem var(--main-color-brown) solid;
    border-radius: 3rem;
}

span{
    font-weight: bolder;
}

.services-title, .main-title{
    font-size: 2rem;
    font-weight: bold;
    text-align: start;
    margin-left: 12rem;
    margin-bottom: 0.5rem;
    color: var(--main-color-green);
}

.title{
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 0.5rem;
    color: var(--main-color-green);
}

.click:hover {
    cursor: pointer;

}

.services-call:hover{
    cursor: pointer;
    box-shadow:  var(--main-color-brown) 0 0.3rem 1rem;
}

.services-call:hover > .click{
    box-shadow:  var(--main-color-brown) 0 0.3rem 1rem;
}

.click{
    color: azure;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--main-color-green);
    padding: 0.5rem;
    min-width: 5rem;  
    font-size: 1rem;  
}

.assistant-container{
    margin-left: 1.5rem;
    display: grid;
    grid-template-areas:  "a b"
                "c d"
                "e f";
}

.assistant-item{
    display: grid;
    grid-template-areas: "x y";
    grid-template-columns: 5.5em auto;
    font-size: 0.9rem;  
    text-align: start;
    padding: 0.5rem 1rem; 
    margin-left: -1rem;
    margin-right: 1rem;
}

.assestant-img{
    max-width: 3rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-right: 0.5rem ;
    margin-top: 50%;
}

.services-box{
    max-width: 30%;
}

.green{
    color: var(--main-color-green);
    font-weight: bold;
}

.services-container--mobile{
    display: none;
}

@media only screen and (max-width: 1350px) {
    .services{
        padding: 0;
        margin: 0;
    }

    .main-title{
        font-size: 1.3rem;
        width: 5rem;
        margin:auto;
        text-align: center;
    }

    .services-container{
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
    }


    .services-box{
        width: auto;
        max-width: none;
        margin: 0.5rem 0;
        padding: 0.5rem;
    }

    .services-container--mobile{
        display: none;
    }
}

@media only screen and (max-width: 780px) {
    .services{
        padding: 0;
        margin: 0;
    }

    .main-title{
        font-size: 1.3rem;
        width: 5rem;
        margin: auto;
        text-align: center;
    }

    .services-container{
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
    }


    .services-box{
        width: auto;
        max-width: none;
        margin: 0.5rem 0;
        padding: 0.5rem;
    }

    .services-container--desctop{
        display: none;
    }

    .assistant-container{
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 0.5rem;
    }

    .assistant-item{
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .assestant-img{
        margin: 0;
    }
    
    .title{
        font-size: 1rem;
    }

    .services-description{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 750px) {
    .services{
        margin: 0;
    }
}
@media only screen and (max-width: 650px) {
    .services{
        margin: 0;
    }
}

@media only screen and (max-width: 550px) {
    .services{
        margin: 0;
    }
}


@media only screen and (max-width: 450px) {
    .services{
        margin: 0;
    }
}


@media only screen and (max-width: 375px) {
    .services{
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 300px) {
    .services{
        margin-top: 0rem;
    }
}

