.form {
    width: 95%;
    margin: 0 0 0 5%;
    border-radius: 5rem;
    padding: 0.5rem;
    /* border: 0.12rem var(--main-color-brown) solid; */
    background: #fdf2da;
}

.grid-box {
    display: grid;
    grid-template-columns: [x] 50% [y] 50%;
}

.title-form {
    text-align: center;
}

.form-checkbox {
    width: fit-content;
    text-align: start;
    margin: auto;
}

.info-text-click {
    display: block;
    margin: 1rem auto;
    background-color: var(--main-color-green);
}

.info-input {
    display: block;
    width: 75%;
    margin: 1rem auto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
}

.height-textArea {
    height: 6.2rem;
}

.checkbox {
    margin-bottom: 0.3rem;

}

.formular-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.enable-display {
    display: none;
}

.anchor-data {
    color: black;
    display: inline;
    cursor: pointer;
}

.anchor-data:hover {
    color: var(--main-color-grey--dark);
}

.info-text-click:hover {
    box-shadow: var(--main-color-brown) 0 0.3rem 1rem;
}

textarea {
    resize: none;
}

@media only screen and (max-width: 1350px) {
    .grid-box {
        display: flex;

    }

}