.personalData-box{
    background-color: var(--main-color-green);
    height: 100%;
    min-height: 100vh;
    padding: 1rem 1rem;
}


.title-light{
    color: var(--main-color-grey);
    padding-top: 3rem;
    width: 75%;
    margin:auto;
}

.personalData-text{
    color: azure;
    margin: auto;
    width: 75%;
}

.subtitle{
    color: azure;
    text-align: left;
    width: 70%;
    font-size: 1.2rem;
    margin: 2rem auto 0.5rem auto;
    font-style: italic;
    font-weight: bolder;
}

.first-ul{
    padding-left: 5rem;
}

.personalData-item{
    margin: 0.5rem 0;
}

.underline{
    text-decoration: underline;
    font-weight: unset;
}

.italic{
    font-style: italic
}

.bold{
    font-size: 1.05rem;
}

.space-top{
    margin-top: 1rem
}

@media only screen and (max-width: 800px){
    .personalData-box{
        padding: 0;
    }


    .title-light{
        color: var(--main-color-grey);
        width: auto;
        margin-top: 0;
        padding-top: 2rem;
    }

    .personalData-text{
        color: azure;
    
    }
    
}