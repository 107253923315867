.homePage-content {
    display: flex;
    justify-content: center;
    margin: 4rem 0;
}

.home-page-container {
    width: 60%;
    background-color: #4f4537e2;
    margin: 15% 0 15% 0;
    padding: 2rem 1.5rem;
    border-radius: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF7E6;
}

.homePage-caption {
    margin: 0 auto;
    padding: 0 5rem;
    text-align: center;
    border-bottom: 0.1rem solid var(--main-color-orange);
    font-size: 2rem;
    color: var(--main-color-orange);
}

.homePage-description {
    text-align: center;
    font-size: 1.1rem;
}

.homePage-address{
    color: var(--main-color-orange);
}

@media only screen and (max-width: 780px) {
    .homePage-content{
        height: auto;
    }

    .home-page-container{
        width: 70%;
        margin: 0;
        padding: 1rem 1rem;
        border-radius: 3rem;
        margin: 1rem;
    }

    .homePage-caption{
        font-size: 1.5rem;
        /* border-bottom: none; */
        padding: 0 0.5rem;
    }

    .homePage-description{
        font-size: 1rem;
    }
}


