.summary-box{
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.title-margin{
    margin-top: 7rem;
    margin-bottom: 1rem;
}

.content-box{
    margin-left: 11.5rem;
    margin-right: 8rem;
    display: grid;
    grid-template-columns: [summary] 40% [formular] 60%;
}

.summary-list{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: [a] 80%  [b] 10%;
    justify-content: center;
    /* background: linear-gradient(90deg, white, #fdf2da); */
    border-radius: 5rem;
    /* border: 0.12rem var(--main-color-brown) solid; */
    
}

.summary-01{
    background: transparent;
    padding: 0;
}   

.summary-02{
    background: transparent;
    padding: 0;
}

.services-item--long{
    height: 3rem;
}

.title-center{
    text-align: center;
    margin-left: 0;
    margin-top: 12rem;
}

.text-center{
    text-align: center;
    margin-bottom: 3.3rem;
}

.mobile-item, .mobile-title{
    display: none;
}

.half-height{
    height: 50vh;
}

@media only screen and (max-width: 1350px){
    .title-margin{
        margin: 7rem auto 1rem auto;
        width: 20rem;
    }

    .summary-list, .content-box{
        display: block;
        margin: 0.5rem 1rem;
    }

    .summary-box{
        align-items: center;
    }

    .check-list-01{
        display: none;
    }

    .services-item, ul{
        display: block;
    }

    .services-item{
        overflow: none;
        white-space:unset;
        height: auto;
        margin-bottom: 1.2rem;
    }

    .mobile-item{
        display: inline;
        font-size: 1rem;
        font-weight: bold;
    }

    .mobile-title{
        display: inline;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .services-list{
        padding: 0;
    }

    .form{
        margin: 0;
        background-color: #fdf2da;
        border-radius: 3rem;
    }

    .grid-box{
        display: block;
    }

    .title-center{
        margin: 0 auto 0rem auto;
        padding-top: 30%;
        width: auto;
    }

}
@media only screen and (max-width: 800px){
    .title-margin{
        margin: 3rem 0 1rem 0;
        width: auto;
    }
   

    .summary-list, .content-box{
        display: block;
        margin: 0.5rem 1rem;
    }

    .summary-box{
        align-items: center;
    }

    .check-list-01{
        display: none;
    }

    .services-item, ul{
        display: block;
    }

    .services-item{
        overflow: none;
        white-space:unset;
        height: auto;
        margin-bottom: 1.2rem;
    }

    .mobile-item{
        display: inline;
        font-size: 1rem;
        font-weight: bold;
    }

    .mobile-title{
        display: inline;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .services-list{
        padding: 0;
    }

    .form{
        margin: 0;
        background-color: #fdf2da;
        border-radius: 3rem;
    }

    .grid-box{
        display: block;
    }

    .title-center{
        margin: 0 auto 0rem auto;
        padding-top: 30%;
        width: auto;
    }

}