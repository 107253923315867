.quotes-container{
    margin-top: 5rem;
    height: 14rem;
    background-color: var(--main-color-green);
    display: flex;
    justify-content: center;
  }

  .quote-container{
    margin: 3rem 2.5rem;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.quote{
    text-align: start;
    /* font-family: 'Oooh Baby', cursive; */
    font-size: 1rem;
    color: var(--main-color-grey);
    display: block;
}

.author{
    /* font-family: 'Oooh Baby', cursive; */
    color: var(--main-color-grey);
    text-align: end;
    font-weight: 100;
    font-size: 0.9rem;
    padding-right: 0.3rem;
}

.button-next{
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 100%;
}

.button-container{
    width: 10rem;
}

.image-next{
    width: 5rem;
    /* margin: 50%; */
}

.quotes-container--mobile{
    display: none;
}

@media only screen and (max-width: 1280px) {
    .quotes-container{
        display: none;
    }

    .quotes-container--mobile{
        margin-top: 5rem;
        height: 10rem;
        background-color: var(--main-color-green);
        display: flex;
        justify-content: center;
    }

    .image-next{
        width: 3rem;
        margin: 0;
    }

    .quote-container{
       margin: 1rem 0;
       width: 50%;
        
    }
}



@media only screen and (max-width: 780px) {
    .quotes-container{
        display: none;
    }

    .quotes-container--mobile{
        margin-top: 5rem;
        height: 15rem;
        background-color: var(--main-color-green);
        display: flex;
        justify-content: center;
    }

    .image-next{
        width: 1.5rem;
        margin: 0;
    }

    .button-container{
        width: 15%;
        justify-content: center;
    }

    .quote-container{
       margin: 1rem 0;
       width: 70%;
        
    }
}

