.prices {
    padding-top: 7rem;
    color: var(--main-color-brown);
}

.services-list,
.check-list-01,
.check-list-02,
.check-list-03 {
    list-style: none;
    margin: 0;
    padding: 2.5rem 1rem 1rem 1rem;
}

.check-list-03 {
    padding-right: 1.5rem;
}

.check-img {
    max-width: 1.7rem;
}

.prices-box {
    display: grid;
    width: 70%;
    margin: auto;
    grid-template-columns: [a] 57% [b] 14% [c] 14% [d] 15%;
    border-radius: 5rem;
}

.services-item,
.check-item {
    height: 2.2rem;
    margin-bottom: 1rem;
}

.services-item {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.services-item::-webkit-scrollbar-thumb {
    background-color: var(--main-color-grey--dark);
    border-radius: 1rem;
}

.services-item::-webkit-scrollbar {
    height: 0.3rem;
    border-radius: 1rem;
    background-color: var(--main-color-grey);
}

.services-item--first,
.check-item--first,
.services-item--last,
.check-item--last {
    font-weight: bolder;
    color: var(--main-color-green);
}

.services-item--first {
    text-align: center;
}

.check-list-01,
.check-list-02,
.check-list-03 {
    text-align: center;
}

.services-list {
    text-align: start;
    border-radius: 5rem 0 0 5rem;
    background: linear-gradient(90deg, white, var(--main-color-orange));
}

.services-item--last,
.check-item--last {
    margin-bottom: 0;
}

.check-list-01 {
    background-color: #fdf2da;
}

.check-list-02 {
    background-color: #faebcc;
}

.check-list-03 {
    background-color: #f9e4ba;
    border-radius: 0 5rem 5rem 0;
}

.prices-ps {
    width: 70%;
    margin: auto;
    text-align: start;
    padding: 0.2rem;
    font-size: 0.8rem;
}

.assistantPrice-box {
    display: grid;
    grid-template-areas:
        'a'
        'a';
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
    background: linear-gradient(90deg, white, #f9e4ba);
    border-radius: 5rem;
    padding: 3rem 0;
}

.assistantPrice-item {
    height: 2.5rem;
}

.assistanPrice-item--last {
    color: var(--main-color-brown);
}

.check-click {
    color: azure;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--main-color-green);
    padding: 0.4rem;
    width: auto;
    font-size: 1rem;
}

.mobile-display {
    display: none;
}

@media only screen and (max-width: 1310px) {
    .desctop-table {
        display: none;
    }

    .mobile-display {
        display: block;
    }

    .prices {
        width: 60%;
        margin: auto;
        padding: 0;
        margin-top: 3rem;
    }

    .mobile-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 1rem;
        background-color: var(--main-color-orange);
        border-radius: 3rem;
    }

    .service-item-mobile {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .assistantPrice-item-mobile {
        font-size: 1rem;
        padding: 1rem;
        text-align: center;
    }

    .assistanPrice-item--last {
        padding-top: 0;
    }

    .services-item--first {
        font-size: 1rem;
        padding: 1rem 1rem;
    }

    .services-options {
        text-align: center;
        display: grid;
        grid-template-columns: [a] 33.5% [b] 33% [c] 33.5%;
        font-size: 1.1rem;
        padding: 0;
    }

    .services-list-mobile {
        padding: 1rem;
    }

    .services-list-mobile--content {
        margin: auto;
        width: fit-content;
    }

    .color-5 {
        background-color: var(--main-color-orange);
        border-radius: 0 0 3rem 3rem;
    }

    .color-8 {
        background-color: #faebcc;
        border-radius: 0 0 3rem 3rem;
    }

    .color-13 {
        background-color: #f9e4ba;
        border-radius: 0 0 3rem 3rem;
    }

    .service-item-click {
        border-radius: 0;
        padding: 0.5rem;
    }

    .center-button {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .services-item--last {
        text-align: center;
    }

    .note {
        font-size: 0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .note-p {
        margin: 0.4rem;
    }

    .active {
        color: var(--main-color-green);
    }

    .space {
        margin-top: 2rem;
    }

    #small-item-long {
        display: inline !important;
        font-size: 0.9rem !important;
        font-weight: unset !important;
    }
}

@media only screen and (max-width: 780px) {
    .desctop-table {
        display: none;
    }

    .mobile-display {
        display: block;
    }

    .prices {
        width: 90%;
        margin: auto;
        padding: 0;
        margin-top: 3rem;
    }

    .mobile-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 1rem;
        background-color: var(--main-color-orange);
        border-radius: 3rem;
    }

    .service-item-mobile {
        font-size: 1rem;
        padding: 0.5rem;
        font-size: 0.9rem;
    }

    .assistantPrice-item-mobile {
        font-size: 0.9rem;
        padding: 1rem;
        text-align: center;
    }

    .assistanPrice-item--last {
        padding-top: 0;
    }

    .services-item--first {
        font-size: 1rem;
        padding: 1rem 1rem;
    }

    .services-options {
        text-align: center;
        display: grid;
        grid-template-columns: [a] 33.5% [b] 33% [c] 33.5%;
        font-size: 1rem;
        padding: 0;
    }

    .services-list-mobile {
        padding: 1rem 1rem;
    }

    .color-5 {
        background-color: var(--main-color-orange);
        border-radius: 0 0 3rem 3rem;
    }

    .color-8 {
        background-color: #faebcc;
        border-radius: 0 0 3rem 3rem;
    }

    .color-13 {
        background-color: #f9e4ba;
        border-radius: 0 0 3rem 3rem;
    }

    .service-item-click {
        border-radius: 0;
        padding: 0.5rem;
    }

    .center-button {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .services-item--last {
        text-align: center;
    }

    .note {
        font-size: 0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .note-p {
        margin: 0.4rem;
    }

    .active {
        color: var(--main-color-green);
    }

    .space {
        margin-top: 2rem;
    }

    #small-item-long {
        display: inline !important;
        font-size: 0.8rem !important;
        font-weight: unset !important;
    }
}
